import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';

import * as $ from 'jquery';// import Jquery here    
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'conecta-portal-acortador-url';
  
  valorURL=undefined;

  constructor( private activatedRoute: ActivatedRoute) { 

   var urlCon=environment.urlCon;
   var apiKeyCon=environment.apikeyCon;
   var urlRedirect= environment.urlRedirectNF;

    this.activatedRoute.params.subscribe( params =>{
      let urlRedirect=params.url;
    
      if(urlRedirect!=undefined || urlRedirect!=null){
        this.valorURL=urlRedirect;
      }

    });
    

    if(this.valorURL!=undefined){
      //01d6b7c366
      $.ajax({
        url: urlCon+"servicios/v1/transversales/url_original?urlParameter="+this.valorURL,
        type: 'GET',
        headers: {
          "X-Api-Key": apiKeyCon
        },
        dataType: 'json',
        contentType: "application/json",
        success: function (res) {
          if(res!=undefined){
              if(res.data.longURL!=undefined){
              window.location.href = res.data.longURL;
              }
              else{
                window.location.href=urlRedirect;
              }
               
          }
          else{
            window.location.href=urlRedirect;
          }
        },
        error: function (data) {
          window.location.href=urlRedirect;
        }
  
        
      });
  

    }


  }


}
