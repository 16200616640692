import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from  './app.component';
const routes: Routes = [

  { path:':url', component: AppComponent},
  { path:'**', pathMatch:'full', redirectTo:'/not_found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
